<template>
  <div>
    <a-card>
      <!-- 查询卡片主体 -->
      <div class="table-operator">
        <a-form layout="inline">
          <a-row :gutter="0">
            <a-col :md="6" :sm="24">
              <a-form-item label="公司主体名称:">
                <a-input
                  v-model="queryParam.name"
                  allowClear
                  placeholder="请输入公司主体名称"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="企业统一社会信用代码:">
                <a-input
                  v-model="queryParam.usc"
                  allowClear
                  placeholder="请输入企业统一社会信用代码"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="企业类型:">
                <a-select
                    style="width: 180px"
                    v-model="queryParam.type"
                    allowClear
                    placeholder="请选择"
                >
                  <a-select-option v-for="item in companyType" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="(!advanced && 4) || 24"
              :sm="24"
              style="text-align: right"
            >
              <span
                class="table-page-search-submitButtons"
                :style="
                  (advanced && { float: 'right', overflow: 'hidden' }) || {}
                "
              >
                <a-button type="primary" @click="dataSearch">查询</a-button>
                <a-button
                  style="margin-left: 8px"
                  @click="() => ((queryParam = {}), this.init())"
                  >重置</a-button
                >
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 表格主体 -->
      <a-table
        rowKey="id"
        :pagination="pagination"
        :columns="columns"
        :dataSource="data"
        :scroll="{ x: 800 }"
        @change="changePageSize"
      >
        <span slot="action" slot-scope="text, record" class="blueColor">
          <a @click="editTaxRate(record)">修改</a>&nbsp;&nbsp;
        </span>
      </a-table>
      <!-- 弹窗设置 -->
      <a-modal
        title="税点配置"
        :width="1080"
        :visible="showCompanyTaxRate"
        :confirmLoading="confirmLoading"
        destroyOnClose
        @ok="handleTaxRate"
        @cancel="handleCancel"
      >
        <a-spin :spinning="confirmLoading">
          <a-form
            layout="inline"
            :form="form"
            style="line-height: 0;padding: 0.3em;"
            class="finContractEditnew"
          >
            <a-row :gutter="0">
              <a-col>
                <a-form-item label="公司主体名称：">
                  <span>{{ companyDetail.name }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="0">
              <a-col>
                <a-form-item label="企业统一社会信用代码：">
                  <span>{{ companyDetail.usc }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="0">
              <a-col>
                <a-form-item label="企业类型:">
                  <span>{{ companyDetail.entityTypeDesc }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row
              :gutter="0"
              v-for="(item, index) in taxInfo"
              v-bind:key="index"
            >
              <a-col :md="3" :sm="24">
                <a-form-item :label="item.costTypeName" :colon="false">
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="税点">
                  <a-input v-model="item.taxRate" style="width: 80px" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-spin>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
  

import moment from "moment";
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";

export default {
  name: "finContractParty",
  data() {
    return {
      companyDetail: {},
      companyTaxDetail: {},
      taxInfo: [],
      data: [],
      showCompanyTaxRate: false,
      visible: false,
      confirmLoading: false,
      advanced: false,
      form: this.$form.createForm(this),
      queryParam: {
        name: "",
        usc: "",
        type: undefined,
      },
      companyType: [
        { id: 1, name: "小规模纳税人" },
        { id: 2, name: "一般纳税人" }
      ],
      pagination: defaultPagination(() => {}),
      current: 1,
      pageSize: 20,
      columns: [
        {
          title: "公司主体名称",
          dataIndex: "name",
          key: "id",
          fixed: "left"
        },
        {
          title: "企业统一社会信用代码",
          dataIndex: "usc",
          key: "usc",
        },
        {
          title: "企业类型",
          dataIndex: "entityTypeDesc",
          key: "entityTypeDesc",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          key: "action",
          width: 140,
          fixed: "right"
        }
      ]
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  methods: {
    moment,
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.init(this.current, this.pageSize);
    },
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "signEntity/listCompanyEntity",
        withCredentials: true,
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (!res.data.success) {
          this.$message.error(res.data.returnMsg);
          return;
        }
        this.data = res.data.rows;
        if (res.data.rows) {
          this.data = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      });
    },
    editTaxRate(val) {
      this.companyDetail = Object.assign({}, val);
      this.confirmLoading = true;
      axios({
        url:   this.$baseUrl + "signEntity/listTaxRateConfig",
        withCredentials: true,
        method: "GET",
        params: {
          legalEntityName: this.companyDetail.name,
        }
      })
        .then(res => {
          this.confirmLoading = false;
          if (!res.data.success) {
            this.showCompanyTaxRate = false;
            this.$message.error(res.data.returnMsg);
            return;
          }
          this.companyTaxDetail = res.data.obj;
          this.taxInfo = res.data.obj.costTypeTaxRateList;
          this.showCompanyTaxRate = true;
        })
        .catch(error => {
          this.confirmLoading = false;
          this.showCompanyTaxRate = false;
          this.$message.error(error.message);
        });
    },
    handleTaxRate() {
      this.showCompanyTaxRate = true;
      let obj = {};
      let errorMsg = this.validateTaxRate(this.taxInfo);
      if (errorMsg !== "success") {
        this.$message.error(errorMsg);
        return;
      }
      this.confirmLoading = true;
      obj.legalEntityId = this.companyTaxDetail.legalEntityId;
      obj.taxRateMap = this.getUpdateTaxRateMap(this.taxInfo);
      console.log(obj);
      axios({
        url:   this.$baseUrl + "signEntity/updateTaxRateConfig",
        method: "POST",
        data: obj
      })
        .then(res => {
          this.confirmLoading = false;
          if (res.data.success) {
            this.$message.success("保存成功！");
            this.showCompanyTaxRate = false;
            this.init(this.current, this.pageSize);
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          this.confirmLoading = false;
          this.$message.error(error.message);
        });
    },
    validateTaxRate(data) {
      let msg = "success";
      let numberReg = new RegExp("^[0-9]+(.[0-9]{1,4})?$");
      for (let i = 0; i < data.length; i++) {
        if ((!data[i].taxRate && data[i].taxRate * 1 !== 0) || !numberReg.test(data[i].taxRate * 1)) {
          msg = data[i].costTypeName + "请填写正确税点！必须是数字，4个小数点范围";
          break;
        }
      }
      return msg;
    },
    getUpdateTaxRateMap(data){
      let taxRates = {};
      for (let i = 0; i < data.length; i++) {
        if (data[i].taxRate) {
          taxRates[data[i].costTypeId] = data[i].taxRate;
        }
      }
      return taxRates;
    },
    selectList() {
      this.init();
    },
    handleCancel() {
      this.showCompanyTaxRate = false;
    },
    dataSearch() {
      this.init();
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 24px;
}
</style>
